@import 'primeicons/primeicons.css';
@import 'quill/dist/quill.core.css';

@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

:root {
  font-size: 15.25px;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  color: var(--text-color);
  background: var(--surface-ground);
  font-family: var(--font-family);
}
